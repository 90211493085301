<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :editable="editable"
            label="LBLDEPT"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="DEVICE_CD"
            codeAttrVal1="Y"
            type="none"
            itemText="codeName"
            itemValue="code"
            name="deviceTypeCd"
            label="디바이스구분"
            v-model="searchParam.deviceTypeCd"
            @input="getColumns"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="griddept"
          title="부서별 권한"
          tableId="griddept"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :isExcelDown="false"
          :columns="griddept.columns"
          :data="griddept.data"
          :editable="editable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="LBLSAVE" icon="save" @btnClicked="saveDept" />
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getColumns" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="griduser"
          title="사용자별 권한"
          tableId="griduser"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :isExcelDown="false"
          :columns="griduser.columns"
          :data="griduser.data"
          :editable="editable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="LBLSAVE" icon="save" @btnClicked="saveUser" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'user-group-by-user',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        deptCd: '',
        retireFlag: 'N',
        deviceTypeCd: 'P',
      },
      griddept: {
        columns: [],
        data: [],
      },
      griduser: {
        columns: [],
        data: [],
      },
      editable: true,
      listUrl: '',
      userlistUrl: '',
      deptlistUrl: '',
      saveUserUrl: '',
      saveDeptUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sys.auth.grp.list.url;
      this.userlistUrl = selectConfig.sys.auth.userauth.list.url;
      this.deptlistUrl = selectConfig.sys.auth.deptauth.list.url;
      this.saveUserUrl = transactionConfig.sys.auth.userauth.insert.url;
      this.saveDeptUrl = transactionConfig.sys.auth.deptauth.insert.url;
      this.getColumns();
    },
    getColumns() {
      var authlist = []
      this.griddept.columns = [];
      this.griduser.columns = [];
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
        defaultFlag: 'N',
        deviceTypeCd: this.searchParam.deviceTypeCd,
      };
      this.$http.request((_result) => {
        /**
         * 2024-01-11 kdh
         * 업체 포탈을 사용하기 전까지 해당과 같이 처리
         */
        _result.data = this.$_.reject(_result.data, { sysAuthGrpId: 'SAGI000020' })
        if (this.$store.getters.user.userId === 'U000000001') {
          authlist = _result.data;
        } else {
          authlist = this.$_.filter(_result.data, item => {return item['sysAuthGrpId'] !== 'SAGI000000'});
        }
        this.griddept.columns = [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:110px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'left',
            sortable: true,
          },
          {
            name: 'sysAuthGrpId',
            field: 'sysAuthGrpId',
            label: '권한',
            align: 'center',
            type: 'multiSelect',
            itemText: 'sysAuthGrpName',
            itemValue: 'sysAuthGrpId',
            style: 'width:65%',
            isArray: false,
            comboItems: authlist,
            sortable: false,
          },
        ];
        this.griduser.columns = [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:110px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: 'LBLUSER',
            align: 'center',
            sortable: true,
          },
          {
            name: 'sysAuthGrpId',
            field: 'sysAuthGrpId',
            label: '권한',
            align: 'center',
            type: 'multiSelect',
            itemText: 'sysAuthGrpName',
            itemValue: 'sysAuthGrpId',
            style: 'width:55%',
            isArray: false,
            comboItems: authlist,
            sortable: false,
          },
        ];
        this.getDeptList();
        this.getUserList();
      },);
    },
    getDeptList() {
      this.$http.url = this.deptlistUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.griddept.data = _result.data;
      },);
    },
    getUserList() {
      this.$http.url = this.userlistUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (this.$store.getters.user.userId === 'U000000001') {
          this.griduser.data = _result.data;
        } else {
          this.griduser.data = this.$_.filter(_result.data, item => {return item['userId'] !== 'U000000001'});
        }
      },);
    },
    saveDept() {
      let saveData = this.griddept.data.filter( x => {
        return x.editFlag == 'U'
      });
      this.$http.url = this.saveDeptUrl;
      this.$http.type = 'POST';
      this.$http.param = saveData;
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getDeptList();
      });
    },
    saveUser() {
      let saveData = this.griduser.data.filter( x => {
        return x.editFlag == 'U'
      });
      console.log(saveData)
      this.$http.url = this.saveUserUrl;
      this.$http.type = 'POST';
      this.$http.param = saveData;
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getUserList();
      });
    },
  }
};
</script>